<template>
  <v-row
    style="background-color: white"
    align="center"
    justify="center"
    class="text-center"
  >
    <v-col cols="12">
      <span style="color: #1a347f; font-weight: 900; font-size: 32px"
        >Welcome Back, Super Admin</span
      >
      <br />
      <span style="color: #000; font-weight: 400; font-size: 28px"
        >Please Select business type to continue</span
      >
    </v-col>

    <v-card
      style="margin: 20px"
      width="302px"
      height="251.67px"
      :class="Livestock ? 'tab-color' : ''"
      elevation="4"
      @click="aaaaa('ธุรกิจสุกรและสัตว์เคี้ยวเอื้อง')"
    >
      <v-row align="center" justify="center">
        <v-col cols="12" class="mt-12" style="text-align: -webkit-center">
          <v-img
            src="@/assets/livestock.png"
            max-width="153.07px"
            max-height="85.12px"
          />
        </v-col>
        <span
          style="
            position: absolute;
            width: 85px;
            height: 36px;
            left: 108px;
            top: 185.83px;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 36px;
            text-align: center;
            color: #1a347f;
          "
          >Livestock</span
        >
      </v-row>
    </v-card>

    <v-card
      style="margin: 20px"
      width="302px"
      height="251.67px"
      :class="Pet ? 'tab-color' : ''"
      elevation="4"
      @click="aaaaa('ธุรกิจสัตว์เลี้ยง โรงพยาบาลสัตว์ เพ็ทช็อป')"
    >
      <v-row align="center" justify="center">
        <v-col cols="12" class="mt-12" style="text-align: -webkit-center">
          <v-img
            src="@/assets/pet.png"
            max-width="138.84px"
            max-height="107.66px"
          />
        </v-col>
        <span
          style="
            position: absolute;
            width: 85px;
            height: 36px;
            left: 108px;
            top: 185.83px;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 36px;
            text-align: center;
            color: #1a347f;
          "
          >Pet</span
        >
      </v-row>
    </v-card>

    <v-card
      style="margin: 20px"
      width="302px"
      height="251.67px"
      :class="Poultry ? 'tab-color' : ''"
      elevation="4"
      @click="aaaaa('ธุรกิจสัตว์ปีก')"
    >
      <v-row align="center" justify="center">
        <v-col cols="12" class="mt-12" style="text-align: -webkit-center">
          <v-img
            src="@/assets/poultry.png"
            max-width="97px"
            max-height="114px"
          />
        </v-col>
        <span
          style="
            position: absolute;
            width: 85px;
            height: 36px;
            left: 108px;
            top: 185.83px;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 36px;
            text-align: center;
            color: #1a347f;
          "
          >Poultry</span
        >
      </v-row>
    </v-card>
    <v-col cols="12">
      <v-btn
        dark
        class="rounded-xl px-12"
        width="171"
        height="39"
        style="background: linear-gradient(268.1deg, #07204b, #1a347f)"
        @click="submit()"
        >submit</v-btn
      >
    </v-col>
  </v-row>
</template>


<script>
import { Decode, Encode } from "@/services";
export default {
  data() {
    return {
      Livestock: false,
      Pet: false,
      Poultry: false,
      selected: "",
    };
  },
  created() {
    //   Livestock
    if (Object.prototype.hasOwnProperty.call(localStorage, "userBiAdmin")) {
      var userData = JSON.parse(
        Decode.decode(localStorage.getItem("userBiAdmin"))
      );
    //   console.log("userData", userData.user.type);
      if (userData.user.type !== "ADMIN") {
        localStorage.setItem("selectedType", Encode.encode(userData.user.type));
        var selectedType = JSON.parse(
          Decode.decode(localStorage.getItem("selectedType"))
        );
        console.log("selectedType", selectedType);
        this.$router.push("/Dashboard");
      }
    }
  },
  methods: {
    // ธุรกิจสัตว์ปีก
    // ธุรกิจสุกรและสัตว์เคี้ยวเอื้อง
    // ธุรกิจสัตว์เลี้ยง โรงพยาบาลสัตว์ เพ็ทช็อป
    aaaaa(val) {
      console.log("val", val);
      if (val == "ธุรกิจสุกรและสัตว์เคี้ยวเอื้อง") {
        this.Livestock = true;
        this.Pet = false;
        this.Poultry = false;
      } else if (val == "ธุรกิจสัตว์เลี้ยง โรงพยาบาลสัตว์ เพ็ทช็อป") {
        this.Livestock = false;
        this.Pet = true;
        this.Poultry = false;
      } else if (val == "ธุรกิจสัตว์ปีก") {
        this.Livestock = false;
        this.Pet = false;
        this.Poultry = true;
      }
      this.selected = val;
    },
    submit() {
      //   console.log("aaa", this.selected);
      if (this.selected) {
        localStorage.setItem("selectedType", Encode.encode(this.selected));
        this.$router.push("/Dashboard");
      } else {
        this.$swal.fire({
          icon: "error",
          text: `กรุณาเลือกประเภท`,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
};
</script>

<style scoped>
.tab-color {
  border: 2px solid #1a347f;
  box-sizing: border-box;
  border-radius: 4px;
}
/* 
.tab-color {
  border: 5px solid #9f9f9f;
  border-radius: 55px;
} */
</style>